
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref } from "vue"
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import SendToCashModal from "./modals/SendToCashModal.vue";
import CancelSaleModal from "./modals/CancelSaleModal.vue";
import TableSaleModule from "@/store/modules/sales/modules/table/index" 
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import NewSaleModule from "@/store/modules/sales/modules/new-sales";
import { formatToDatatable } from "@/common/formaterToDatatable";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { FormTypes } from "@/core/enums/form-types";
import { getModule } from "vuex-module-decorators";
import { translate } from "@/core/plugins/i18n";
import { Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import * as Validations from "yup";
import TableSalesToBillModule from "@/store/modules/bill/modules/table-sales-to-bill";
import SaleTypeModule from "@/store/modules/saleType/modules/new-saletype";
import PDFViewer from "@/components/modals/general/PDFViewer.vue";
import { debounce } from "lodash";
import hotkeys from 'hotkeys-js';


export default defineComponent({
    components:{
          Field
        , Form
        , ModuleMaster
        , Datatable
        , Datarow
        , SelectFilterRemote
        , SendToCashModal
        , CancelSaleModal
        , PDFViewer
    },
    setup() {
        //Propiedades
        const moduleTable = getModule(TableSaleModule);
        const moduleNewSale = getModule(NewSaleModule);
        const moduleTableSalesToBill = getModule(TableSalesToBillModule);
        const moduleSalesType = getModule(SaleTypeModule);
        const openModalSendToCash = ref(HTMLButtonElement);
        const openModalCancelSale = ref(HTMLButtonElement);
        const saleId = ref("");
        const selected = ref("");
        const action = ref(1);
        let modeForm = ref(FormTypes.Create);
        const router = useRouter();
        const formSearch = ref();
        const openPDF = ref(HTMLButtonElement); 

        //Schema
        const validationSchema = Validations.object().shape({
            clienteId: Validations.string().nullable().default(null).label("clienteId"),
            folio: Validations.string().nullable().default(null).label("folio"),
            statusId: Validations.string().nullable().default("-1").label("statusId")
        });

        moduleTable.SEARCH_TABLE_DATA();
          
        /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false },
            { PropName: "invoince", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
            { PropName: "customer", HeadLabel: "Cliente", Type: "text", VisibleInGrid: true },
            { PropName: "fechaCreacion", HeadLabel: "Fecha", Type: "text", VisibleInGrid: true },
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true },
            { PropName: "agent", HeadLabel: "Vendedor", Type: "text", VisibleInGrid: true },            
            { PropName: "stage", HeadLabel: "Estatus", Type: "text", VisibleInGrid: true },            
        ]);

        const labels = reactive({
            placeHolderToFieldSearch: "Buscar Venta",
            titleNewCrudAction: "Nueva Venta(ALT+N)",
        });

        //METODOS

        const filterNameChange = debounce((filterName: string) => {
            moduleTable.UPDATE_FILTERS({filterName});
        }, 550, {leading: false, trailing: true});
        
        const handleChangePage = (page: number) => {
            moduleTable.CHANGE_PAGE({ currentPage: page });
        }
       
        const handleSearchClient = (name: string) => {
          moduleTableSalesToBill.SEARCH_CLIENTS(name);
        }

        const handleViewRecib = (id: string) => {
            let actionModule: Promise<boolean>;

            actionModule = moduleNewSale.GET_RECEIP_VIEW(
                id,
            );

            actionModule.then((isCorrect2) => {
                if(isCorrect2){
                    if(openPDF.value) {
                            (openPDF.value as unknown as HTMLButtonElement).click();
                    }
                }
            });
            
        }

        const handleCrudAction = (action: string | number = "N", id: string) => {
            moduleNewSale.RESET_NEW_SALE();
            moduleSalesType.SEARCH_SALES_TYPE({
                filterName: "",
                isTypeSaleQuote: false
            });

            switch(action){
                case "N":
                    // setCurrentPageBreadcrumbs( translate('BREADCRUMB.NEWSALES'), [translate('BREADCRUMB.MODULES'), { name: translate('BREADCRUMB.SALES'), to: "sales" }]);
                    router.push({
                            name: "/sales/sale",
                            params: {
                                action: "new"
                            }
                        });
                    break;
                case "M":
                    // setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALES'), [translate('BREADCRUMB.MODULES')]);
                    moduleNewSale.SEARCH_INFO_SALE(id);
                    router.push({
                        name: "/sales/sale",
                        params: {
                            action: "edit"
                        }
                    });
                    break;
                case "V":
                    // setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALES'), [translate('BREADCRUMB.MODULES')]);
                    moduleNewSale.SEARCH_INFO_SALE(id);
                    router.push({
                        name: "/sales/sale",
                        params: {
                            action: "view"
                        }
                    });
                    break;
                case "C":
                    handlerChangeStatus(id);
                    break;
                case "P":
                    handlerSenToPay(id);
                    break;
                case "EP":
                    handlerSenToShuedule(id);
                    break;
                case "D":
                    handleViewRecib(id);
                    break;
                default:
                    break;
            }
        }

        const handlerSenToPay = (id: string) => {
            if(openModalSendToCash.value){
                saleId.value = id;
                action.value = 1;
                (openModalSendToCash.value as unknown as HTMLButtonElement).click();
            }
        }

        const handlerSenToShuedule = (id: string) => {
            if(openModalSendToCash.value){
                saleId.value = id;
                action.value = 2;
                (openModalSendToCash.value as unknown as HTMLButtonElement).click();
            }
        }

        const handlerChangeStatus = (id: string) => {
            if(openModalCancelSale.value){
                saleId.value = id;
                (openModalCancelSale.value as unknown as HTMLButtonElement).click();
            }
            //  Swal.fire({
            //             title: '¿Está seguro de cancelar el venta?',
            //             text: "Se procederá a cancelar la venta para este pedido y no podrá revertir la acción",
            //             icon: 'warning',
            //             showCancelButton: true,
            //             confirmButtonColor: '#000000',
            //             cancelButtonColor: '#7E829',
            //             confirmButtonText: 'Si, cancelar'
            //             }).then((result) => {
            //             if (result.isConfirmed) {
            //                 moduleNewSale.DISABLE_SALE(id);
            //             }});
        }

        const showOptionSchedule = (id: string) => {
            const sale = records.value.find(ir => ir.id === id);
            let isSaleHome = false;

            if(sale){
                isSaleHome = sale.type == 2 && (sale.stage == "Creado" || sale.stage == 'Enviado a pago');
            }

            return isSaleHome;
        }

        const showOptionApproval = (id: string) => {
            const sale = records.value.find(ir => ir.id === id);
            let isForAppointment = false;

            if(sale){
                isForAppointment = sale.isForAppointment;
            }

            return isForAppointment;
        }

        const searchFilter = (data: any) => {
            console.log(data);
            
            var cliente = clientsCombo.value?.find(x => x.id == data.clienteId);

            var clientName = cliente?.name;

            moduleTable.UPDATE_FILTERS({
                invoice: data.folio
                , rfc: clientName
                , status: data.statusId
            });
        }

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(moduleTable.records, configTable);
        });

        const records = computed(() => moduleTable.records);
        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() => moduleTable.pagination.pageSize);
        const clientsCombo = computed(() => moduleTableSalesToBill.getClientOptions);
        const pdf = computed(() => moduleNewSale.getbase64PDF);
        
        onMounted(() => {
                setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALES'), [translate('BREADCRUMB.MODULES')]);

                formSearch.value.setFieldValue('statusId', '0');

                // formSearch.value.setValues({
                //     statusId: "0"
                // });

                hotkeys('alt+n', (event, handler) => {
                   handleCrudAction("N", '');
                });
        });

        onBeforeUnmount(() => {
                hotkeys.unbind('alt+n');
        });

        return {
            labels,
            headers,
            renderRows,
            totalRecords,
            records,
            sizePage,
            router
            , openPDF
            , pdf
            , modeForm
            , validationSchema
            , formSearch
            , clientsCombo
            , openModalSendToCash
            , saleId
            , action
            , filterNameChange
            , openModalCancelSale
            , selected

            ,showOptionApproval
            , handlerSenToShuedule
            , showOptionSchedule
            , searchFilter
            , handleChangePage
            , handleCrudAction
            , handlerChangeStatus
            , handlerSenToPay
            , handleSearchClient
            , handleViewRecib

        }
    }
})
